<template>
  <div class="h-full">
    <div v-if="!currentClient.client_initialized" class="container mx-auto">
      <ClientSimpleClientNotInitialized class="mt-20 w-1/2 mx-auto " />
    </div>
    <div v-else>
      <BaseLayout v-if="isLicenseValid && !loading">
        <template #primarySection>
          <section
            aria-labelledby="primary-heading"
            class="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-secondary p-10"
          >
            <slot class="mt-12" />
            <SharedCustomNotification />
          </section>
        </template>

        <template #secondarySection>
          <aside class="hidden lg:order-first lg:block lg:flex-shrink-0">
            <div
              class="relative flex h-full w-auto max-w-[15rem] flex-col overflow-y-auto"
            >
              <ClientNavigation :client-mode="client.client_mode" />
            </div>
          </aside>
        </template>
      </BaseLayout>
      <ErrorView
        v-if="errorDuringLoading"
        :error="globalError"
        :on-click-method="() => clearError({ redirect: '/' })"
      />
      <InfoModal
        text="Um fortzufahren sind Zahlungsdetails erforderlich"
        titel="Zahlungsdetails erforderlich"
        :show="showPaymentDetailsModal"
        button-confirm-text="Zahlungsdetails eingeben"
        button-cancel-text="Zurück"
        @on-confirm="redirectToStripePaymentDetails"
        @on-cancel="showPaymentDetailsModal = false; navigateTo('/')"
      />
    </div>
  </div>
</template>

<script setup>
import BaseLayout from '~/components/shared/BaseLayout.vue'
import { AppException } from '~/utils/other'
import {
  useCurrentClient,
  useClientTimezone,
  useCurrentUserSession, useCurrentServiceprovider
} from '~/composables/states'
import InfoModal from '~/components/shared/modals/InfoModal.vue'
import ErrorView from '~/components/shared/ErrorView.vue'

const { $logger, $toast, $api, $gql, $dayjs, $sentry } = useNuxtApp()
const route = useRoute()
const { signOut, getSession } = useAuth()

const { initializeCurrentClient, currentClient, setCurrentClientUUID, getCurrentClientIdToSchema } = useCurrentClient()
const { setClientTimezone } = useClientTimezone()
const globalError = ref({})
const { isAdminUser } = useCurrentUserSession()
const { serviceprovider } = useCurrentServiceprovider()

const client = ref({
  client_id_pk: null,
  client_name: null,
  client_address: null,
  client_mode: null
})

// license related
const license = ref({})
const loading = ref(true)
const errorDuringLoading = ref(false)
const showPaymentDetailsModal = ref(false)

const timezoneQuery = computed(() => {
  return `query {
    domain: s_${getCurrentClientIdToSchema()}_tbl_domain_config {
      domain_config_timezone
    }
  }`
})

const isLicenseValid = computed(() => {
  // only if client already exists
  if (!currentClient.value.client_initialized || loading) {
    return true
  }

  const currentStatus = license.value?.subscriptionStatus || null

  if (license.value.paymentDetailsRequired) {
    showPaymentDetailsModal.value = true
  }

  if (currentStatus) {
    if (currentStatus === 'active' || currentStatus === 'trialing') {
      return true
    } else {
      showPaymentDetailsModal.value = true
      return false
    }
  }

  errorDuringLoading.value = true
  // globalError.value = { title: 'Api konnte nicht erreicht werden', message: 'Bitte versuchen Sie es später erneut.', code: 500 }

  return false
})

watch(
  () => route.params.session_id,
  async () => {
    // page has session_id after redirect from successful stripe payment
    await handleClientPagePreparation()
  }
)

watch(
  () => currentClient.value.client_initialized,
  async () => {
    await handleClientPagePreparation()
  }
)

// check if jwt expired
watch(() => route.path, () => {
  $api.user.validateSession().then(async (res) => {
    // if response code not 200, then logout
    $logger.info('validateSession: ' + res.status)
    if (res.status !== 200) {
      $logger.warn('Session expired')
      $api.user.endKeycloakSession()
      $sentry.flush()
      await signOut('keycloak')
    }
  }).catch(async (e) => {
    // login failed, try to relogin or logout
    $logger.info('error in validateSession')
    const session = await getSession()
    $logger.info(session)
  })
})

await handleClientPagePreparation()

async function redirectToStripePaymentDetails () {
  try {
    const res = await $api.serviceprovider.createCheckoutSession(serviceprovider.value.uuid)

    // redirect to checkout session
    window.location.href = res.data
    showPaymentDetailsModal.value = false
  } catch (e) {
    $logger.error(e)
    $toast.error('Fehler', 'Zahlungsdetails konnten nicht abgerufen werden')
  }
}

async function setClientBaseData () {
  try {
    await initializeCurrentClient(client.value.client_id_pk)
    client.value = currentClient.value

    // view not allowed for serviceprovider
    if (client.value.client_mode === 'quickcheck' && !isAdminUser) {
      // redirect to quickcheck page
      await navigateTo('/')
    }

    if (client.value.client_initialized) {
      // set client timezone if forest config is available (only after first collector run)
      // if not initialized, error because tbl_domain_config does not exist
      const { data } = await $gql.query(timezoneQuery.value)
      if (data.domain.length > 0) {
        setClientTimezone(data.domain[0].domain_config_timezone)
        $dayjs.setDayjsTimezone(data.domain[0].domain_config_timezone)
      }
    }
  } catch (e) {
    $logger.error(e)
    globalError.value = {
      title: 'Fehler beim Abrufen der Daten',
      message: 'Bitte versuchen Sie es später erneut.',
      code: 500
    }
  }
}

async function handleClientPagePreparation () {
  try {
    // get client id
    client.value.client_id_pk = route.params.clientId
    setCurrentClientUUID(route.params.clientId.toString())
    $sentry.setTag('client', route.params.clientId.toString())

    if (client.value.client_id_pk) {
      await setClientBaseData()

      if (currentClient.value.client_initialized) {
        await getLicense()
        if (errorDuringLoading.value) {
          return
        }

        // await getWhitelabelData()
        checkCollectorFirstRun().catch(e => $logger.error(e)) // not important for page loading

        if (!isLicenseValid) {
          showPaymentDetailsModal.value = true
        }
      }
    }
  } catch (e) {
    errorDuringLoading.value = true
    isLicenseValid.value = false
    globalError.value = { title: 'Seite konnte nicht geladen werden', message: e.message, code: 500 }

    if (e instanceof AppException) {
      $toast.error(e.title, e.message)
    } else {
      $toast.error('Fehler', e.message)
    }

    $logger.error(e)
  }
}

// check if collector is installed, if not, show notification
async function checkCollectorFirstRun () {
  try {
    const isCollectorInstalledQuery = `
        {
          collector: s_${getCurrentClientIdToSchema()}_tbl_status_collector {
            version
          }
        }
      `
    const { data } = await $gql.query(isCollectorInstalledQuery)
    if (data.collector?.[0]?.version === null && isLicenseValid && !loading) {
      $toast.warn(
        'Collector nicht eingerichtet',
        'Mehr unter: https://sec-auditor.com/docs/'
      )
    }
  } catch (e) {
    $logger.error(e)
  }
}

async function getLicense () {
  try {
    const res = await $api.client.getClientLicense(client.value.client_id_pk)
    license.value = res.data
  } catch (e) {
    if (e.response.status === 403) {
      globalError.value = {
        title: 'Keine Berechtigung',
        message: 'Sie sind nicht berechtigt auf diese Ressource zuzugreifen',
        code: 403
      }
    } else {
      globalError.value = {
        title: 'Fehler beim Abrufen der Lizenz',
        message: 'Bitte versuchen Sie es später erneut.',
        code: 500
      }
    }
    errorDuringLoading.value = true
  } finally {
    loading.value = false
  }
}
</script>

<style></style>
